<template>
  <div>
    <container class="flex flex-col gap-12 py-12">
      <div class="grid grid-cols-1 gap-12">
        <div class="col-span-1 xl:col-span-7 justify-between flex flex-col">
          <div class="abstract-circles abstract-circles-stake">
            <img src="@/assets/images/abstract-circles.svg" alt="">
          </div>
          <unfenced-title class="text-black hidden lg:flex" color="fence-dark" textAlign="left">Stake <span class="text-gradient">SEEN</span> </unfenced-title>
          <div class="description text-gray-400 font-medium py-4 text-lg mb-6">Deposit your SEEN in the pool and start earning fees generated by the SEEN Protocol.</div>
          <div v-if="state.shareOfThePool && (state.shareOfThePool > 0)" class="staking-chart-container mb-12">
            <staking-chart :shareOfThePool="state.shareOfThePool" />
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            <div class="flex flex-col items-center xl:items-start gap-4">
              <div class="text-sm text-gray-400 font-semibold uppercase">
                TVL (USD)
                <i
                  class="fas fa-info-circle"
                  v-tooltip="{
                    text: 'Total Value Locked (USD)'
                  }"
                ></i>
              </div>
              <div class="text-3xl font-black">
                {{ totalStakedUsd }}
              </div>
            </div>

            <div class="flex flex-col items-center xl:items-start gap-4">
              <div class="text-sm text-gray-400 font-semibold uppercase">
                TOTAL SEEN LOCKED
                <i class="fas fa-info-circle" v-tooltip="{text: 'Total SEEN Locked'}"></i>
              </div>
              <div class="text-3xl font-black flex">
                <img src="@/assets/icons/icon--seen.svg" alt="SEEN" class="mr-1" />
                {{ formatCrypto(state.totalStaked, true) }}
              </div>
            </div>

            <div class="flex flex-col items-center xl:items-start gap-4">
              <div class="text-sm text-gray-400 font-semibold uppercase">
                Your share of the pool
                <i
                  class="fas fa-info-circle"
                  v-tooltip="{
                    text: 'Your share of the pool'
                  }"
                ></i>
              </div>
              <div class="text-3xl font-black">{{ formatCrypto(state.shareOfThePool, true) }}%</div>
            </div>

            <div class="flex flex-col items-center xl:items-start gap-4">
              <div class="text-sm text-gray-400 font-semibold uppercase">
                Your xSEEN balance
                <i
                  class="fas fa-info-circle"
                  v-tooltip="{
                    text: 'Your balance of xSEEN which represents your staked SEEN'
                  }"
                ></i>
              </div>
              <div class="text-3xl font-black flex">
                {{ formatCrypto(state.xSeenBalance, true) }}
              </div>
            </div>

            <div class="flex flex-col items-center xl:items-start gap-4">
              <div class="text-sm text-gray-400 font-semibold uppercase">
                xSEEN to SEEN ratio
                <i
                  class="fas fa-info-circle"
                  v-tooltip="{
                    text: 'The amount of SEEN that 1 xSEEN is worth'
                  }"
                ></i>
              </div>
              <div class="text-3xl font-black flex">
                {{ formatCrypto(state.xSeenToSeenRatio, true, state.xSeenToSeenRatio === 0 ? 2 : 8) }}
              </div>
            </div>

            <div class="flex flex-col items-center xl:items-start gap-4">
              <div class="text-sm text-gray-400 font-semibold uppercase">
                Staked SEEN (with reward)
                <i
                  class="fas fa-info-circle"
                  v-tooltip="{
                    text: 'SEEN staked\r\nincluding reward'
                  }"
                ></i>
              </div>
              <div class="text-3xl font-black flex">
                {{ formatCrypto(state.seenIncludingReward, true) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cards flex flex-col xl:flex-row">
        <stake-or-withdraw-card :amount="seenBalance" :state="state" :onStaked="initialize" class="mr-0 mb-12 xl:mb-0 xl:mr-12" type-of="stake" />
        <stake-or-withdraw-card :amount="seenBalance" :state="state" :onStaked="initialize" type-of="withdraw" />
      </div>
      <div class="cards flex flex-col xl:flex-row">
        <distribution-card :onDistributed="initialize" />
      </div>

      <div class="grid grid-cols-1 gap-12 justify-between my-12">
        <unfenced-title class="text-black hidden" textAlign="left">How to Stake <span class="text-gradient">SEEN?</span></unfenced-title>

        <div class="grid grid-cols-12 gap-10">
          <span class="bg-white rounded-lg shadow-lifted p-8 text-center items-center flex flex-col gap-4 col-span-12 lg:col-span-6 xl:col-span-3">
            <span class="text-white bg-black font-black p-6 rounded-lg text-3xl number-square">1</span>
            <span class="font-medium text-gray-400">Deposit your SEEN</span>
          </span>
          <span class="bg-white rounded-lg shadow-lifted p-8 text-center items-center flex flex-col gap-4 col-span-12 lg:col-span-6 xl:col-span-3">
            <span class="text-white bg-black font-black p-6 rounded-lg text-3xl number-square">2</span>
            <span class="font-medium text-gray-400">Receive xSEEN in return</span>
          </span>
          <span class="bg-white rounded-lg shadow-lifted p-8 text-center items-center flex flex-col gap-4 col-span-12 lg:col-span-6 xl:col-span-3">
            <span class="text-white bg-black font-black p-6 rounded-lg text-3xl number-square">3</span>
            <span class="font-medium text-gray-400">Start earning a portion of protocol fees</span>
          </span>
          <span class="bg-white rounded-lg shadow-lifted p-8 text-center items-center flex flex-col gap-4 col-span-12 lg:col-span-6 xl:col-span-3">
            <span class="text-white bg-black font-black p-6 rounded-lg text-3xl number-square">4</span>
            <span class="font-medium text-gray-400">Redeem SEEN rewards by clicking withdraw*</span>
          </span>
        </div>

        <p class="font-medium text-gray-400">* All deposits are subject to a 3 day lockup period to protect against sandwich attacks</p>
      </div>
    </container>
  </div>
</template>

<script>
import {computed, reactive, watchEffect} from "vue"
import {useMeta} from "vue-meta"
import {useStore} from "vuex"

import UnfencedTitle from "@/components/UnfencedTitle"
import Container from "@/components/Container"
import DistributionCard from "@/views/stake/components/DistributionCard"
import StakeOrWithdrawCard from "@/views/stake/components/StakeOrWithdrawCard"
import useExchangeRate from "@/hooks/useExchangeRate"
import {useSEENContract, useStakingContract} from "@/hooks/useContract"
import useWeb3 from "@/connectors/hooks"
import {formatEther} from "@ethersproject/units"
import BigNumber from "bignumber.js"
import {Web3Provider, WebSocketProvider} from "@ethersproject/providers"
import StakingChart from "@/views/stake/components/StakingChart"

export default {
  name: "Stake",
  components: {
    UnfencedTitle,
    Container,
    DistributionCard,
    StakeOrWithdrawCard,
    StakingChart
  },
  setup() {
    const {account, provider} = useWeb3()
    const {meta} = useMeta({
      title: "Staking"
    })

    const store = useStore()

    const state = reactive({
      shareOfThePool: 0,
      totalStaked: 0,
      totalStakedUsd: 0,
      totalxSeenSupply: 0,
      contractEthBalance: 0,
      xSeenBalance: 0,
      xSeenToSeenRatio: 0,
      seenIncludingReward: 0
    })
    const {formatCrypto, formatCurrency, convertSeenToUSDAndFormat} = useExchangeRate()

    const seenBalance = computed(() => {
      return store.getters["application/balance"].seen
    })

    const initialize = async () => {
      const contract = useSEENContract()
      let balance = await contract.balanceOf(process.env.VUE_APP_XSEEN_CONTRACT_ADDRESS)
      state.totalStaked = formatEther(balance.toString())

      const stakeContract = useStakingContract()
      let totalSupplyxSeen = await stakeContract.totalSupply()
      state.totalxSeenSupply = formatEther(totalSupplyxSeen.toString())

      const xSeenToSeenConversionRate = BigNumber(BigNumber(balance.toString())).dividedBy(totalSupplyxSeen.toString())
      state.xSeenToSeenRatio = xSeenToSeenConversionRate.toString()

      const library = provider.value ? new Web3Provider(provider.value) : new WebSocketProvider(process.env.VUE_APP_NETWORK_URL)

      const cb = await library.getBalance(process.env.VUE_APP_SEEN_CONTRACT_ADDRESS)
      state.contractEthBalance = formatEther(cb.toString())
    }

    watchEffect(async () => {
      if (account.value && state.totalxSeenSupply && state.totalStaked && state.xSeenToSeenRatio) {
        const stakeContract = useStakingContract()
        let balanceOf = await stakeContract.balanceOf(account.value)
        balanceOf = formatEther(balanceOf.toString())
        state.xSeenBalance = BigNumber(balanceOf)
        let share = BigNumber(balanceOf).dividedBy(state.totalxSeenSupply)
        state.shareOfThePool = share.multipliedBy(100)
        const stakedSeenIncludingReward = BigNumber(state.xSeenToSeenRatio).multipliedBy(state.xSeenBalance)
        state.seenIncludingReward = stakedSeenIncludingReward.toString()

      }
    })

    watchEffect(async () => {
      if (!provider?.value) {
        return
      }

      await initialize();
    })

    const totalStakedUsd = computed(() => {
      return convertSeenToUSDAndFormat(state.totalStaked)
    })

    const feesEarned = computed(() => {
      if (!state.shareOfThePool) {
        return 0
      }
      const contractEthBalance = new BigNumber(state.totalStaked).multipliedBy(state.shareOfThePool.dividedBy(100))
      return contractEthBalance
    })

    return {
      formatCrypto,
      formatCurrency,
      seenBalance,
      state,
      totalStakedUsd,
      feesEarned,
      initialize,
    }
  }
}
</script>

<style scoped lang="scss">
.text-gradient {
  background: -webkit-linear-gradient(0deg, #11998e 1.74%, #38ef7d 100%);
  background-clip: text;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #26c785;
}
.staking-chart-container {
  min-width: 400px;
  min-height: 400px;
  margin-left: auto;
  margin-right: auto;
}
.number-square {
  width: 84px;
  height: 84px;
}
.abstract-circles-stake {
  top: 275px;
  @screen lg {
    right: 35px;
  }
}
</style>
